import React from "react";
import Helmet from "react-helmet";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import IntroHeader from "../../components/IntroHeader";
import ProductDetails from "../../components/ProductDetails";
import Accent from "../../components/Accent";
import Divider from "../../components/Divider";
import styles from "./styles.module.less";
import img from "../../images/home-loan-faq.png";

const HLFAQs = () => {
    const header = (
        <Header
            active={["tools-and-resources", "home-loan-faqs"]}
            theme="blue-ribbon"
        />
    );

    const introHeader = "Frequently asked questions";
    const par =
        "When it comes to buying a home, we know what you need to know.";
    const alt =
        "Answers to Frequently Asked Questions - FAQ Icon | Bay Equity Home Loans";

    return (
        <Layout hasHero={false} header={header}>
            <Helmet>
                <title>Home Loan FAQs | Bay Equity Home Loans</title>
                <meta
                    name="description"
                    content="Check out some of the most frequently asked questions about home loans. If you don’t find the answer you’re looking for, give us a call. We’re here to help."
                />
            </Helmet>
            <IntroHeader header={introHeader} par={par} img={img} alt={alt} />
            <Divider />
            <h2 className={styles.DropdownTitle}>General</h2>
            <div className={styles.accent}>
                <Accent />
            </div>
            <ProductDetails
                title={"I’m thinking of buying a home. Where do I start?"}
                border={true}>
                <p style={{ marginBottom: "20px" }}>
                    If you’re just starting your home search, we recommend
                    checking out our{" "}
                    <a className={styles.parOrange} href="/get-started">
                        getting started
                    </a>{" "}
                    page with step-by-step instructions for navigating the
                    home-buying process.
                </p>
                <p>
                    However, if you’ve done your research and you’re ready to
                    get active, then{" "}
                    <a
                        className={styles.parOrange}
                        href="/find-your-loan-officer">
                        contact one of our loan officers today
                    </a>
                    . We’re here to help you, no matter where you are on the
                    road to homeownership.
                </p>
            </ProductDetails>
            <ProductDetails
                title={"How do I calculate how much my mortgage will be?"}
                border={true}>
                <p>
                    Everyone’s financial situation is very different. While
                    there is no one-size-fits-all equation, we do have a few{" "}
                    <a className={styles.parOrange} href="/loan-calculators">
                        home loan calculators
                    </a>{" "}
                    that can help you estimate your future mortgage amount.
                </p>
                <br />
                <p>
                    If these estimated numbers start to feel comfortable,{" "}
                    <a
                        className={styles.parOrange}
                        href="/find-your-loan-officer">
                        talk to a loan officer
                    </a>{" "}
                    to figure out exactly how much home you can afford and
                    exactly what your monthly mortgage payments would be.
                </p>
            </ProductDetails>
            <ProductDetails
                title={"What are the benefits of buying vs. renting a home?"}
                border={true}>
                <h5 className={styles.Subhead}>It carries tax benefits</h5>
                <p>
                    A portion of the interest paid on your mortgage – which is
                    the majority of your payment the first few years – is
                    tax-deductible. That means more money for your family, more
                    money to take that extra vacation and more money for that
                    romantic evening out.
                </p>
                <h5 className={styles.Subhead}>
                    Freedom to fit your home to your personality
                </h5>
                <p>
                    Buying a home allows you the freedom to customize your home
                    to better fit you and your family. Also, as you upgrade your
                    home, its value will increase and your equity will grow.
                </p>
                <h5 className={styles.Subhead}>
                    You’re not paying someone else’s mortgage
                </h5>
                <p>
                    Many believe the best way to build wealth is through
                    homeownership. Plus, there’s security in not having to worry
                    about moving every time your lease is up.
                </p>
            </ProductDetails>
            <ProductDetails
                title={"What are the benefits of owning a vacation home?"}>
                <ul style={{ margin: 0 }}>
                    <li>
                        Potential extra tax benefits on top of your first
                        mortgage
                    </li>
                    <li>
                        Reliable, customized vacation for you and your family
                    </li>
                    <li>
                        You can buy a vacation home with as little as 10% down
                    </li>
                    <li>Potential property appreciation</li>
                </ul>
            </ProductDetails>
            <h2 className={styles.DropdownTitle}>Standard home loans</h2>
            <div className={styles.accent}>
                <Accent />
            </div>
            <ProductDetails
                title={"What is an adjustable-rate mortgage?"}
                border={true}>
                <p>
                    An adjustable-rate mortgage is a home loan where the
                    interest rate changes periodically. Visit our{" "}
                    <a
                        className={styles.parOrange}
                        href="/adjustable-rate-mortgage">
                        adjustable-rate mortgage
                    </a>{" "}
                    page to learn all about this particular home loan product.
                </p>
            </ProductDetails>
            <ProductDetails title={"What is a fixed-rate mortgage?"}>
                <p>
                    A fixed-rate mortgage is a home loan where the interest rate
                    stays the same for the life of the loan. Visit our{" "}
                    <a className={styles.parOrange} href="/fixed-rate-mortgage">
                        fixed-rate mortgage
                    </a>{" "}
                    page to learn all about this particular home loan product.
                </p>
            </ProductDetails>
            <h2 className={styles.DropdownTitle}>Specialty home loans</h2>
            <div className={styles.accent}>
                <Accent />
            </div>
            <ProductDetails
                title={"What specialty loan programs are available?"}
                border={true}>
                <p>
                    Specialty loans are creative mortgage solutions for
                    homebuyers who need unique loan programs for their unique
                    situations. Visit our{" "}
                    <a className={styles.parOrange} href="/specialty-loans">
                        specialty loan
                    </a>{" "}
                    page to learn all about the many specialty home loan
                    products available.
                </p>
                <br />
                <p>Or visit any of these particular pages for more details:</p>
                <ul>
                    <li>
                        <a className={styles.parOrange} href="/fha-loan">
                            FHA loans
                        </a>
                    </li>
                    <li>
                        <a className={styles.parOrange} href="/jumbo-loan">
                            Jumbo loans
                        </a>
                    </li>
                    <li>
                        <a className={styles.parOrange} href="/usda-home-loan">
                            USDA loans
                        </a>
                    </li>
                    <li>
                        <a className={styles.parOrange} href="/va-loans">
                            VA loans
                        </a>
                    </li>
                    <li>
                        <a
                            className={styles.parOrange}
                            href="/reverse-mortgage">
                            Reverse mortgages
                        </a>
                    </li>
                </ul>
            </ProductDetails>
            <ProductDetails title={"What is an FHA home loan?"} border={true}>
                <p>
                    A Federal Housing Association (FHA) Loan is a
                    government-insured loan that helps people buy and refinance
                    real estate. Visit our{" "}
                    <a className={styles.parOrange} href="/fha-loan">
                        FHA home loan
                    </a>{" "}
                    page to learn all about this particular home loan product.
                </p>
            </ProductDetails>
            <ProductDetails title={"What is a jumbo loan?"} border={true}>
                <p>
                    Jumbo loans are a special financing option for loan amounts
                    exceeding the conventional conforming loan limit. Visit our{" "}
                    <a className={styles.parOrange} href="/jumbo-loan">
                        jumbo loan
                    </a>{" "}
                    page to learn all about this particular home loan product.
                </p>
            </ProductDetails>
            <ProductDetails title={"What is a USDA home loan?"} border={true}>
                <p>
                    A USDA loan is a mortgage with up to 100% financing for
                    owner-occupied properties in designated USDA rural areas.
                    Visit our{" "}
                    <a className={styles.parOrange} href="/usda-home-loan">
                        USDA home loan
                    </a>{" "}
                    page to learn all about this particular home loan product.
                </p>
            </ProductDetails>
            <ProductDetails title={"What is a VA home loan?"} border={true}>
                <p>
                    VA home loans are for currently or formerly serving military
                    and require no down payment. Visit our{" "}
                    <a className={styles.parOrange} href="/va-loans">
                        VA home loan
                    </a>{" "}
                    page to learn all about this particular home loan product.
                </p>
            </ProductDetails>
            <ProductDetails title={"What is a reverse mortgage?"} border={true}>
                <p>
                    A reverse mortgage is a type of home loan for seniors or for
                    people over a certain age that allows you to convert a
                    portion of the equity in your home into cash. Visit our{" "}
                    <a className={styles.parOrange} href="/reverse-mortgage">
                        reverse mortgage
                    </a>{" "}
                    page to learn all about this particular home loan product.
                </p>
                <h5 className={styles.Subhead}>
                    Are the proceeds I receive taxable?
                </h5>
                <p>
                    Loan advances from reverse mortgages are generally not
                    considered taxable income. This means the proceeds you
                    receive from a reverse mortgage are generally tax-free.
                    Please consult a tax professional.
                </p>
                <h5 className={styles.Subhead}>
                    Can I use a reverse mortgage to purchase a home?
                </h5>
                <p>
                    Yes. You would need to make a down payment and apply the
                    proceeds from your reverse mortgage at the time of purchase.
                    The down payment is determined by the home value minus the
                    amount of proceeds received from the reverse mortgage (after
                    subtracting loan costs). You will not need to make monthly
                    reverse mortgage payments while you live in the home or
                    until a maturity event occurs.
                </p>
                <h5 className={styles.Subhead}>
                    What are the costs of a reverse mortgage?
                </h5>
                <p>
                    Costs include origination and processing fees, third-party
                    closing costs (just like a first mortgage) and FHA mortgage
                    insurance. Most of these costs can be financed into the loan
                    and may vary depending on which product you select. In
                    addition, there may be a counseling fee that will be
                    collected up front or financed within the loan.
                </p>
            </ProductDetails>
            {/*
            <ProductDetails title={"What is a HARP home loan?"}border={true}>
                <p>The Home Affordable Refinance Program (HARP) used to offer 
                    refinance options to homeowners whose mortgage balances were 
                    higher than their property value. However, this is now called 
                    enhanced LTV mortgages—a kind of refinance loan for people who 
                    have been making mortgage payments on time but are burdened 
                    by high loan-to-value (LTV) ratios.</p><br /> 
                <p>Visit our <a className={styles.parOrange} href="/enhanced-ltv-mortgage">enhanced LTV mortgage</a> page to learn all about this 
                    particular home loan product.</p>
            </ProductDetails>
            */}
            <ProductDetails
                title={"What down payment assistance programs are available?"}>
                <p>
                    Down payment assistance grants vary from state to state,
                    which means the requirements can also change. If you’re
                    needing a little financial nudge in the right direction,{" "}
                    <a
                        className={styles.parOrange}
                        href="/find-your-loan-officer">
                        talk to one of our loan officers
                    </a>
                    . They can help you find the down payment assistance program
                    you might need to buy the home you really want.
                </p>
            </ProductDetails>
            <h2 className={styles.DropdownTitle}>Refinancing options</h2>
            <div className={styles.accent}>
                <Accent />
            </div>
            <ProductDetails
                title={"What is a home loan refinance?"}
                border={true}>
                <p>
                    Refinancing a mortgage allows homeowners to negotiate a new
                    mortgage agreement, turning the equity in their home into
                    cash. Visit our{" "}
                    <a className={styles.parOrange} href="/refinance-mortgage">
                        refinance home loan
                    </a>{" "}
                    page to learn all about this particular home loan product.
                </p>
            </ProductDetails>
            <ProductDetails title={"What is a VA refinance loan?"}>
                <p>
                    A VA streamline refinance is an interest rate reduction
                    refinancing loan (IRRRL) designed to support military
                    service members. Visit our{" "}
                    <a className={styles.parOrange} href="/va-refinance">
                        VA refinance
                    </a>{" "}
                    page to learn all about this particular home loan product.
                </p>
            </ProductDetails>
            <h2 className={styles.DropdownTitle}>Our company</h2>
            <div className={styles.accent}>
                <Accent />
            </div>
            <ProductDetails title={"Is Bay Equity currently hiring?"}>
                <p>
                    Yes. We’re always on the lookout for top performers in the
                    mortgage industry to join our team. If you’re interested in
                    a career at Bay Equity, take a look at{" "}
                    <a className={styles.parOrange} href="/bay-equity-careers">
                        our updated job board
                    </a>{" "}
                    and apply today!
                </p>
            </ProductDetails>
        </Layout>
    );
};

export default HLFAQs;
